<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Rallybook</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              tabindex="-1"
              icon
              v-on="on"
              @click="showDetails = !showDetails"
            >
              <v-icon>{{
                showDetails ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'
              }}</v-icon></v-btn
            >
          </template>
          <span>Toggle detailed view</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="isValid" class="mx-3">
          <v-row dense>
            <v-autocomplete
              ref="autoCompleteField"
              v-model="newBonus.name"
              tabindex="1"
              :items="BonusLocationsRidingOnlyGetter"
              item-value="name"
              color="blue darken-4"
              dense
              item-text="name"
              label="Bonus"
              auto-select-first
              :rules="[(v) => !!v || 'Item is required']"
              @change="populateForm(newBonus.name)"
            ></v-autocomplete>
          </v-row>
          <v-row dense>
            <v-text-field
              v-model="newBonus.comment"
              tabindex="-1"
              :rules="[
                validateHTML(newBonus.comment),
                validateLength(newBonus.comment, 2000),
                validateCharacters(newBonus.comment)
              ]"
              label="Comment"
              clearable
              dense
              counter="2000"
            ></v-text-field>
          </v-row>
          <v-row dense>
            <v-text-field
              v-model="newBonus.description"
              tabindex="-1"
              :rules="[
                validateHTML(newBonus.description),
                validateLength(newBonus.description, 2000),
                validateCharacters(newBonus.description)
              ]"
              label="Description (does not show in Garmin Basecamp)"
              clearable
              dense
              counter="2000"
            ></v-text-field>
          </v-row>
          <v-row dense>
            <v-text-field
              v-model="newBonus.points"
              tabindex="2"
              label="Point value"
              type="number"
              required
              dense
              :rules="[
                validateWholeNumber(newBonus.points),
                validateMinimum(newBonus.points, -100000),
                validateMaximum(newBonus.points, 100000)
              ]"
              @change="updateForm"
            ></v-text-field>
            <v-text-field
              v-model="newBonus.page"
              tabindex="3"
              label="Rallybook page"
              type="number"
              dense
              :rules="[
                validateHTML(newBonus.page),
                validateLength(newBonus.page, 5),
                validateCharacters(newBonus.page)
              ]"
              @change="updateForm"
            ></v-text-field>
          </v-row>
          <v-row dense>
            <v-col>
              <v-radio-group
                v-model="newBonus.category"
                row
                @change="updateForm"
              >
                <v-radio
                  tabindex="4"
                  label="All day"
                  color="green"
                  value="A"
                ></v-radio>
                <v-radio label="Daylight" color="blue" value="D"></v-radio>
                <v-radio label="Timed" color="red" value="T"></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="newBonus.category === 'T'"
                v-model="newBonus.timerestrictions"
                label="Time restrictions"
                :rules="[
                  validateHTML(newBonus.timerestrictions),
                  validateLength(newBonus.timerestrictions, 50),
                  validateCharacters(newBonus.timerestrictions)
                ]"
                @change="updateForm"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="newBonus.unpaved"
                label="Unpaved roads"
                justify="end"
                @change="updateForm"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="showDetails" dense>
            <v-text-field
              v-model="newBonus.latitude"
              label="Latitude"
              required
              readonly
              dense
            ></v-text-field>
            <v-text-field
              v-model="newBonus.longitude"
              label="Longitude"
              required
              readonly
              dense
            ></v-text-field>
          </v-row>
          <v-row v-if="showDetails" dense>
            <v-text-field
              v-model="newBonus.longname"
              label="Long name"
              required
              readonly
              dense
            ></v-text-field>
            <v-text-field
              v-model="newBonus.timezoneLong"
              label="Timezone"
              readonly
              required
              dense
            ></v-text-field>
          </v-row>
          <v-row v-if="showDetails" dense>
            <v-text-field
              v-model="newBonus.sunrise"
              label="Sunrise @ UTC"
              readonly
              required
              dense
            ></v-text-field>
            <v-text-field
              v-model="newBonus.sunset"
              label="Sunset @ UTC"
              readonly
              required
              dense
            ></v-text-field>
          </v-row>
          <v-row v-if="showDetails" dense>
            <v-text-field
              v-model="newBonus.value"
              label="Value"
              readonly
              dense
            ></v-text-field>
            <v-text-field
              v-model="newBonus.symbol"
              label="Symbol"
              readonly
              dense
            ></v-text-field>
          </v-row>

          <v-row v-if="showDetails" dense>
            <v-text-field
              v-model="newBonus.proximity"
              label="Proximity warning"
              readonly
              dense
            ></v-text-field>
          </v-row>

          <v-row dense>
            <v-btn
              tabindex="5"
              :disabled="!isValid"
              color="success"
              class="mr-4"
              elevation="0"
              @click="submitForm"
            >
              Submit
            </v-btn>
            <v-btn color="error" class="mr-4" elevation="0" @click="resetForm">
              Reset Form
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-12">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>{{ chipText }}</v-toolbar-title></v-toolbar
      >
      <v-card-text>
        <v-chip-group column>
          <v-chip
            v-for="bonus in BonusLocationsRidingOnlyGetter"
            :key="bonus.name"
            tabindex="-1"
            small
            pill
            color="orange lighten-4"
            :active="bonus.longname === null"
            @click="populateForm(bonus.name)"
          >
            {{ bonus.name }}</v-chip
          >
        </v-chip-group>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Completed bonus locations</v-toolbar-title></v-toolbar
      >
      <v-card-text>
        <v-chip-group column>
          <v-chip
            v-for="bonus in BonusLocationsRidingOnlyGetter"
            :key="bonus.name"
            tabindex="-1"
            small
            pill
            color="green lighten-4"
            :active="bonus.longname !== null"
            @click="populateForm(bonus.name)"
          >
            {{ bonus.name }}</v-chip
          >
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as inputValidationRules from '@/components/inputValidationRules'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations.js'

export default {
  name: 'RallyBook',
  data() {
    const data = {
      newBonus: new BonusLocationsPrototype(),
      isValid: false,
      chipText: 'Incomplete bonus locations',
      showDetails: false
    }
    return { ...data, ...inputValidationRules }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences,
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsCountGetter',
      'BonusLocationsByNameGetter',
      'BonusLocationsRidingOnlyGetter'
    ])
  },
  mounted: function () {
    this.$refs.autoCompleteField.focus()
  },
  created: function () {
    this.newBonus = new BonusLocationsPrototype()
  },
  methods: {
    ...mapActions('moduleBonusLocations', ['BonusLocationsCreateUpdateAction']),
    async updateForm() {
      if (this.newBonus.name) {
        let result = this.BonusLocationsRidingOnlyGetter.filter(
          (bonuslocation) => bonuslocation.longname === null
        )
        this.chipText = `Incomplete bonus locations - ${
          result.length - 1
        } out of ${this.BonusLocationsCountGetter} remaining`

        if (this.newBonus.category === null) this.newBonus.category = 'A'
        if (this.newBonus.unpaved === null) this.newBonus.unpaved = false
        this.newBonus.setEntryorder()
        await this.newBonus.setTimezone()
        this.newBonus.setSunrise()
        this.newBonus.setSunset()
        this.newBonus.setValue()
        this.newBonus.setLongname()
        this.newBonus.setProximity()
        this.$refs.form.validate()
      }
    },
    populateForm(_bonusName) {
      this.newBonus = { ...this.BonusLocationsByNameGetter(_bonusName) }
      this.newBonus.__proto__ = BonusLocationsPrototype.prototype
      this.updateForm()
    },
    resetForm() {
      this.$refs.form.reset()
    },
    async submitForm() {
      await this.BonusLocationsCreateUpdateAction(
        JSON.parse(JSON.stringify(this.newBonus))
      )
      this.resetForm()
      this.$refs.autoCompleteField.focus()
    }
  }
}
</script>
